import React from "react";
import Carousel  from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../card/Card15";

import {
  Image 
} from "antd";

const Render = ({data, t, timeout}) => {

  // console.log("$:/data ", data);

  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
  }
 
  return (
    <div>
      <Carousel 
        responsive={responsive} 
        autoPlaySpeed={timeout}  
        infinite={true}
        autoPlay={true}
        arrows={false} 
        showDots={false}
      >
        {data && data.length > 0 && data.map((item, index) => (
          <Card
            key={index}
            preview={false}
            image={item?.file}
            url={item?.url}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default Render;