import React from "react";

import {
  Image,
  Button,
  Menu
} from "antd";

import {
  LogoutOutlined,
  UserOutlined
} from "@ant-design/icons"

const Render = ({data, events}) => {

  // console.log("$:/profile/data ", data);
  

  const getItem = (key, icon, label) => {

    return {
      key, 
      icon, 
      label
    }
  }

  const items = [
    getItem('/profile',  <UserOutlined />, 'Профайл',),
    getItem('/logout',  <LogoutOutlined />, 'Гарах',),
  ]

  return (
    <div className="text-center">
      <div className="py-4 bg-blue-400 text-white uppercase font-bold justify-center">Хэрэглэгчийн булан</div>  
      <div className="mt-4">
        <Menu 
          mode="vertical"
          theme="light"
          // style={{ width: 256 }}
          items={items}
          onClick={events.handleOnClick}
        />

        <div>
          {/* <Image src={data?.imageUrl}/> */}
          {/* <div>
            <div><span>Мэйл хаяг</span>: {data?.email}</div>
            <div><span>Овог</span>: {data?.familyName}</div>
            <div><span>Нэр</span>: {data?.givenName}</div>
          </div> */}


        </div>
      </div>
      {/* <Button onClick={() => events.handleOnClick()}>Гарах</Button> */}
    </div>
  )
}

export default Render;