

function Card({
  title,
  subtitle,
  description,
  index
}) {

  var sign = Math.sign(subtitle);
  var classname = "";

  switch(sign) {
    case -1:
      classname = "text-red-500 border-red-500"
      break
    case 1: 
      classname = "text-green-500 border-green-500" 
      break
    case 0: 
      classname = "text-gray-300 border-gray-300"
      break
    default: 
      classname = ""
  }

  return (
    <div key={index + '1234'} className="my-2">   
      <div className="flex">
        <div className="border-l-2 border-gray-500 mr-3"></div>
        <div className="mr-3 text-white">{title.split("-")[0]}</div>
        <div className={`mr-3 border ${classname} px-1 rounded`}>{subtitle.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
        <div className="mr-3 text-white">{description.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}₮</div>
      </div>
    </div>
  );
};


export default Card;