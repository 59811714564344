import React from "react";

import Card from "../../../components/card/Card15";
import Carousel from "../../../components/carousel/Carousel02";
import TimeLine from "../../../components/bko/TimeLine";

const Render = ({t, title, timeout, data, index}) => {

  console.log("$:/data ", data);

  return (
    <div className="grid grid-cols-12"> 
      
      <div className="col-span-12">
        <div className="">
          <Carousel
            t={t}
            timeout={timeout}
            data={data}
          />
          {/* <Carousel> */}
            {/* {data.map((item, index) => (
              <Card
                key={index}
                preview={false}
                image={item?.file}
                url={item?.url}
              />
            ))} */}
          {/* </Carousel> */}
        </div>
          {/* <Carousel data={data} index={index}/> */}
      </div>
    </div>
  )
};

export default Render;