import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Grid, AppBar as MuiAppBar, Toolbar } from "@mui/material";
import IndexStatus from "./IndexStatus";
import NavbarLogo from "./NavbarLogo";
import SubMenu from "./SubMenu";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarMenuDropdown from "./NavbarMenuDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors";

import {
  globaltrade
} from "../../utils/data";

import { useTranslation } from "react-i18next";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  background-color: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Navbar = () => {
  const { t } = useTranslation();

  const auth = useSelector(authSelector);  
  return (
    <>
      <div className="mx-6">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <NavbarLogo />
          </div>
          <div className="col-span-10 justify-left">
            {/* <NavbarMenuDropdown /> */}
            {t("main_navbar_description")}
          </div>
          <div className="col-span-1 text-right flex justify-between items-center">
            {/* <IndexStatus t={t} /> */}
            <NavbarLanguagesDropdown />
            {/* <NavbarUserDropdown /> */}
            {/* {auth.isAuthenticated && <NavbarUserDropdown />} */}
          </div>
        </div>
      </div>
      <div className="bg-gray-700">
        <SubMenu t={t} data={globaltrade} />
      </div>
    </>
  );
};

export default withTheme(Navbar);
