import React from "react";

import Card09 from "../../../components/card/Card09";
import Card15 from "../../../components/card/Card15";


const TradeBoard = ({t, title, data, link}) => {

  return (
    <a href="https://trade.marketinfo.mn" target="_blank"> 
      <Card09
        title={t("main_trade_today")}
        link="https://trade.marketinfo.mn"
        /> 
      <div className="">
        <Card15
          preview={false}
          image="/static/img/realtime.png"
        />
      </div>
    </a>
  )
};

export default TradeBoard;