import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../redux/api";

import { setCredentials } from "../../redux/slices/authSlice";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleOnSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const { email, password } = values;
    const result = await login({ email, password });
    const { error, data } = result;

    if (!!error) {
      const { data } = error;
      const message = data?.error?.message || t("error_wrong");
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }

    if (!!data?.success && !!data?.data?.token) {
      localStorage.setItem("yuboku-annotator", data?.data?.token);
      localStorage.setItem(
        "yuboku",
        JSON.stringify({ isAuthenticated: true, user: data?.data?.user })
      );
      const token = localStorage.getItem("yuboku-annotator");
      dispatch(
        setCredentials({
          token: data?.data?.token,
          user: data?.data?.user,
          isAuthenticated: true,
        })
      );
      if (!!token) {
        navigate("/");
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("error_auth_email_wrong"))
          .max(255)
          .required(t("error_auth_email_required")),
        password: Yup.string().max(255).required(t("error_auth_pass_required")),
      })}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("auth_form_email")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label={t("auth_form_password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            style={{
              marginTop: "12px",
            }}
          >
            {t("auth_form_submit")}
          </Button>
          {/* <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button> */}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
