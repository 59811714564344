import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import Sidebar from "../components/sidebar/Sidebar";

import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";

import Footer from "../components/Footer";
import SideBar from "../pages/home/Sidebar";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    z-index: 10;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

// background: ${(props) => props.theme.palette.background.default};
const MainContent = styled(Paper)`
  flex: 1;

  background: #fff;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <AppContent>
        <Navbar />
        <MainContent>
          <div className="grid grid-cols-8 gap-4 container mx-auto">
            <div className="col-span-8 md:col-span-6 lg:col-span-6 xl:col-span-6 main">
              <Outlet />  
            </div>  
            <div className="col-span-8 md:col-span-2 border-l border-t pl-2 border-gray-200 ">
            {/* <div className="hidden md:block col-span-2 border-l border-t pl-2 border-gray-200 "> */}
              <SideBar />
            </div>
          </div>
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default MainLayout;
