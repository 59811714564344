import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApiMutation } from "../redux/api";

import { Form, notification } from "antd";
import moment from "moment";

import Share from "../components/form/Share";
import { setShare } from "../redux/slices/shareSlice";
import { setModal } from "../redux/slices/modalSlice";
import { companySelector, shareSelector } from "../redux/selectors";
import dayjs from "dayjs";

const Render = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [api] = useApiMutation();
  const share = useSelector(shareSelector);
  const company = useSelector(companySelector);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [share?.reset]);

  const handleOnFinish = async (values) => {

    var body = {
      ...values
    };
    
    if(values?.tradeDate) {
      body.tradeDate = dayjs(values?.tradeDate).format("YYYY-MM-DD")
      body.cost = parseFloat(`${values.cost}`.replace(/,/g, ''))
    };
    
    await api({
      method: body?.id && "PUT" || "POST",
      url: "/portfolio/shares",
      body: body
    })
      .then((result) => {
        // console.log("$:/result ", result);

        if(!!result?.error) {

          // console.log("$:/error ", result.error);

          notification.error({
            message: t("main_error"),
            description: t("main_share_failed_edit"),
            position: "rightTop"
          });
        }

        if(result.data) {
          const { data } = result
          dispatch(setShare({
            reset: !share?.reset,
            detail: {
              ...data,
              perCost: data?.cost
            }
          }));
            dispatch(setModal({
              modal: false
            }))
            notification.success({
              message: t("main_success"),
              description: t("main_share_success_add"),
              position: "rightTop"
            });
          }
      })
  }

  return (
    <div>
      <Share
        form={form}
        t={t}
        data={{
          detail: share?.detail,
          company: company?.list
        }}
        events={{
          handleFinish: handleOnFinish
        }}
      />
    </div>
  ) 
};

export default Render;