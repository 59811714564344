import { isRejectedWithValue } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiBase = createApi({
  reducerPath: "apiBase",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://service.marketinfo.mn", //process.env.REACT_APP_SERVER_URL,
    _prepareHeaders: (headers, { getState }) => {
      
      const auth = getState().auth.token;
      
      if (auth) {
        headers.set("Authorization", `Bearer ${auth}`);
        headers.set("Access-Control-Allow-Origin", "*");
      }
      return headers;
    },
    get prepareHeaders() {
      return this._prepareHeaders;
    },
    set prepareHeaders(value) {
      this._prepareHeaders = value;
    },
  }),
  endpoints: (builder) => {
    return {
      login: builder.mutation({
        query: (body) => {
          return {
            url: "/users/login",
            method: "POST",
            body,
          };
        },
      }),
      getApi: builder.query({
        query: (suffix) => {
          return suffix;
        }
      }),
      api: builder.mutation({
        query: ({url, method, body}) => {
          var config = {
            url: url,
            method: method
          };

          if(body) {
            config.body = body;
          }

          return config;
        }
      }),
    };
  },
});

export const {
  useLoginMutation,
  useGetApiQuery,
  useApiMutation,
} = apiBase;
