
import React from "react";
import async from "./components/Async";
import DataLayout from "./layouts/Data";
import MainLayout from "./layouts/Main";
import PortfolioLayout from "./layouts/Portfolio";

import AuthLayout from "./layouts/Auth";
import AuthGuard from "./components/guards/AuthGuard";
import Page404 from "./pages/auth/Page404";

import SignIn from "./pages/auth/SignIn";
import { element } from "prop-types";

const NewsDetail = async(() => import("./pages/newsdetail"));
const NewsDetailId = async(() => import("./pages/newsdetailid"));
const NewsList = async(() => import("./pages/newslist"));
const HomeMain = async(() => import("./pages/home"));
const CompanyDetail = async(() => import("./pages/company"));
const Plan = async(() => import("./pages/plan"));
const Infographs = async(() => import("./pages/infographs"));
const Portfolio = async(() => import("./pages/portfolio"));
const Shares = async(() => import("./pages/portfolio/share"));
const Watchlist = async(() => import("./pages/watchlist"));

const Aboutus = async(() => import("./pages/aboutus"));
const Terms = async(() => import("./pages/aboutus/terms"));
const Policy = async(() => import("./pages/aboutus/policy"));

const Profile = async(() => import("./pages/profile"));

const Data = async(() => import("./pages/data"));
const DataMarket = async(() => import("./pages/data/datamarket"));
const DataIndex = async(() => import("./pages/data/dataindex"));
const DataBigMovement = async(() => import("./pages/data/bigmovement"));
const DataTrade = async(() => import("./pages/data/trade"));
const DataShareowner = async(() => import("./pages/data/shareowner"));
const DataInvest = async(() => import("./pages/data/invest"));
const DataMovement = async(() => import("./pages/data/movement"));
const DataMining = async(() => import("./pages/data/mining"));
const DataPack = async(() => import("./pages/data/pack"));
const DataCompanies = async(() => import("./pages/data/companies"));
const DataDivident = async(() => import("./pages/data/divident"));
const DataOthers = async(() => import("./pages/data/others"));
const DataOtc = async(() => import("./pages/data/otc"));
const DataReport = async(() => import("./pages/data/report"));
const DataResearch = async(() => import("./pages/data/research"));
const DataSignal = async(() => import("./pages/data/signal"));
const DataAnalysis = async(() => import("./pages/data/analysis"));

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <HomeMain />,
      },
    ],
  },
  {
    path: "aboutus",
    element: <MainLayout />,
    // element: <DataLayout />,
    children: [
      {
        path: "",
        element: <Aboutus />,
        index: true
      },
      {
        path: "terms",
        element: <Terms />,
        index: true
      },
      {
        path: "policy",
        element: <Policy />,
        index: true
      },
    ]
  },
  {
    path: "news",
    element: <MainLayout />, 
      // <AuthGuard>
      // </AuthGuard>,
    children: [
      {
        path: "",
        element: <NewsList type="main_news"/>
      },
      {
        path: "local",
        element: <NewsList type="main_news_local"/>
      },      
      {
        path: "global",
        element: <NewsList type="main_news_global"/>
      },
      {
        path: "detail",
        element: <NewsDetail />
      },
      {
        path: ":id",
        element: <NewsDetailId />
      },
    ]
  },
  {
    path: "company",
    element: <AuthGuard>
              <MainLayout /> 
            </AuthGuard>,
    children: [
      {
        path: "detail",
        element: <CompanyDetail />
      },
    ],
  },
  {
    path: "portfolio",
    element: <AuthGuard>
              <PortfolioLayout /> 
            </AuthGuard>,
    children: [
      {
        path: "portfolio",
        element: <Portfolio />
      },
      {
        path: "shares",
        element: <Shares />
      }
    ],
  },
  {
    path: "profile",
    element: <AuthGuard>
              <MainLayout /> 
            </AuthGuard>,
    children: [
      {
        path: "",
        element: <Profile />
      }
    ]
  },
  {
    path: "watchlist",
    element: <AuthGuard>
              <MainLayout /> 
            </AuthGuard>,
    children: [
      {
        path: "",
        element: <Watchlist />
      }
    ]
  },
  {
    path: "data",
    element: <AuthGuard>
      <DataLayout /> 
      </AuthGuard>,
    // element: <DataLayout />,
    children: [
      {
        path: "",
        element: <Data />,
        index: true
      },
      {
        path: "market",
        element: <DataMarket />
      },
      {
        path: "index",
        element: <DataIndex />
      },
      {
        path: "bigmovement",
        element: <DataBigMovement />
      },
      {
        path: "trade",
        element: <DataTrade />
      },
      {
        path: "shareowner",
        element: <DataShareowner />
      },
      {
        path: "invest",
        element: <DataInvest />
      },
      {
        path: "nontrademovement",
        element: <DataMovement />
      },
      {
        path: "pack",
        element: <DataPack />
      },
      {
        path: "companies",
        element: <DataCompanies />
      },
      {
        path: "divident",
        element: <DataDivident />
      },
      {
        path: "others",
        element: <DataOthers />
      },
      {
        path: "report",
        element: <DataReport />
      },
      {
        path: "research",
        element: <DataResearch />
      },
      {
        path: "otc",
        element: <DataOtc />
      },
      {
        path: "signal",
        element: <DataSignal />
      },
      {
        path: "analysis",
        element: <DataAnalysis />
      },
      {
        path: "mining",
        element: <DataMining />
      },      
      {
        path: "portfolio",
        element: <Portfolio />
      },
      {
        path: "shares",
        element: <Shares />
      }
    ]
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
