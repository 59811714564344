import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

import { Tooltip, IconButton as MuiIconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { userLogginOut } from "../../redux/slices/authSlice";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    localStorage.setItem("yuboku", "");
    localStorage.setItem("yuboku-annotator", "");
    dispatch(userLogginOut({}));
    navigate("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Sing out!">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
          size="large"
        >
          <UserOutlined />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
