import { Button } from "antd";
import React from "react";

const Info = ({label, disabled}) => {
  

  return (
    <Button disabled={disabled} className="bg-blue-800 text-white">{label}</Button>
  )
}

export default Info;