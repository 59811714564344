import React, { useEffect, useState } from "react";
import { ConfigProvider, theme as AntTheme, notification } from "antd";
import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ja_JP from "antd/locale/ja_JP";
import en_US from "antd/locale/en_US";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import useTheme from "./hooks/useTheme";
import "./styles/antd.css";
import "./styles/index.css";
import createTheme from "./theme";
import "./i18n";
import routes from "./routes.js";
import { isValidToken } from "./utils/jwt";
import { setCredentials, userLogginOut } from "./redux/slices/authSlice";

import { useApiMutation } from "./redux/api";
import { setCompany } from "./redux/slices/companySlice";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";


function App() {

  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.locale("en");
  dayjs.locale("vi");

  const [antLocale, setAntLocale] = useState(ja_JP);
  const {t} = useTranslation()
  const content = useRoutes(routes);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [api] = useApiMutation();

  const { i18n } = useTranslation();

  /* set ant language by i18n's change */
  useEffect(() => {
    if (i18n.language === "en") {
      setAntLocale(en_US);
    } else if (i18n.language === "jp") {
      setAntLocale(ja_JP);
    }
  }, [i18n.language]);

  useEffect(() => {
    const checkAccessToken = () => {
      const accessToken = localStorage.getItem("marketinfo");
      const userData = JSON.parse(localStorage.getItem("marketprofile") || "{}");

      if (
        !!accessToken &&
        !isValidToken(accessToken) &&
        userData?.isAuthenticated === true
      ) {
        dispatch(
          setCredentials({
            token: accessToken,
            isAuthenticated: true,
            isInitialized: true,
            user: userData.user,
          })
        );
      } else {
        dispatch(userLogginOut());
      }
    };
    checkAccessToken(); // enable when the authentication feature available

    loadCompanyList();
  }, []);

  const loadCompanyList = async () => {
    var result = await api({
      url: "/data/company",
      method: "GET",
    });

    const {data, error} = result;
    
    if(!!error) {
      notification.error({
        message: t("main_error_company"),
        position: "rightTop"
      })
    }

    dispatch(setCompany({
      list: data
    }));
  }

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet
        titleTemplate="%s | marketinfo.mn"
        defaultTitle="marketinfo.mn"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          {/* eslint-disable */}
          <ConfigProvider
            locale={antLocale}
            theme={{ algorithm : theme === "DARK" ? AntTheme.darkAlgorithm : AntTheme.defaultAlgorithm }}
          >
            {/* eslint-enable */}
            {/* {auth?.isInitialized && content} -- enable when the authentication feature available*/}
            {content}
            {/* <GoogleOAuthProvider clientId="303902114425-r5rfe11em7pidemi8loeqsin9kp66c1o.apps.googleusercontent.com" >
              <React.StrictMode>
                <GoogleLogin 
                  onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </React.StrictMode>
            </GoogleOAuthProvider> */}
          </ConfigProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
