import React from "react";

import Card03 from "../../../components/card/Card03";
import Carousel from "../../../components/carousel/Carousel01";
import TimeLine from "../../../components/bko/TimeLine";

const DownloadApp = ({t, title, data, link}) => {

  // console.log("$:/download pp " , data);

  return (
    <div className="grid grid-cols-12"> 
      
      <div className="col-span-12">
        <div>
          <Carousel data={data} />
          {/* <img src="/static/img/banner.png" width="full" /> */}
        </div>
        <div className="mt-4 grid grid-cols-12 gap-4">
          <Card03
            outerClassName="mb-4 col-span-6"
            innerClassName="rounded-lg h-14 bg-contain"
            image="/static/img/icon1.png"
            /> 
          <Card03
            outerClassName="mb-4 col-span-6"
            innerClassName="rounded-lg h-14 bg-contain"
            image="/static/img/icon2.png"
            /> 
        </div>
      </div>
    </div>
  )
};

export default DownloadApp;