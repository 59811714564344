import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";


import { authSelector, } from "../../redux/selectors";
import { Navigate } from "react-router-dom";
import { useApiMutation } from "../../redux/api";
import dayjs from "dayjs";
import { message } from "antd";

function AuthGuard({ children }) {
  const auth = useSelector(authSelector);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [api] = useApiMutation();

  useEffect(() => {
    
    loadExpire();
  }, []);


  const loadExpire = async () => {
    var {data} = await api({method: "GET", url: "/payment/status"});
    
    var isBefore = dayjs(data?.expiredDate).isBefore(dayjs())
    // console.log("$:/isbefore ", isBefore);
    if(isBefore == true) {
      // console.log("$:/navigation true", isBefore);

      message.warning("Дата мэдээлэл харах эрх дууссан байна. " + dayjs(data?.expiredDate).format("YYYY/MM/DD"))
      navigate("/profile");
    }
  }

  if (auth?.isAuthenticated == false && auth?.isInitialized == true ) {
    return <Navigate to={"/?auth=false&redirect=" + location.pathname + location.search} />
  }

  // console.log("$:/location ", location)
  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
