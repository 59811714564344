import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import {
  Menu
} from "antd";
import { setData } from "../../redux/slices/dataSlice";

function NavbarMenuDropdown() {
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const onClick = (e) => {
    navigate("/" + e.key);
    dispatch(
      setData({
        key: e.key
      })
    );
  };

  const items = [
    {
      label: t("menu_home"),
      key: "",
    },
    {
      label: t("menu_news"),
      key: "news",
      // children: [
      //   {
      //     label: t("main_news_local"),
      //     key: "news/local"
      //   },
      //   {
      //     label: t("main_news_global"),
      //     key: "news/ global"
      //   }
      // ]
    },
    {
      label: t("menu_package"),
      key: "data/portfolio",
    },
    // {
    //   label: t("menu_watchlist"),
    //   key: "watchlist",
    // },
    // {
    //   label: t("menu_investment"),
    //   key: "investment",
    //   children: [
    //     {
    //       label: t("menu_investment_new"),
    //       key: "news/local"
    //     },
    //     {
    //       label: t("menu_investment_how"),
    //       key: "news/global"
    //     },
    //     {
    //       label: t("menu_investment_company"),
    //       key: "news/global"
    //     },
    //     {
    //       label: t("menu_investment_share"),
    //       key: "news/global"
    //     }
    //   ]
    // },
    {
      label: t("menu_marketdata"),
      key: "data",
      // children: [
      //   {
      //     label: t("menu_marketdata_index"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_change"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_trade"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_pack"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_analysis"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_signal"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_otc"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_deal"),
      //     key: "data"
      //   },
      //   {
      //     label: t("menu_marketdata_movement"),
      //     key: "data"
      //   }
      // ]
    },
  ]

  return (
    <div className="container mx-auto">
      <Menu
        id="menu-appbar"
        mode="horizontal"
        className="justify-center"
        align="center"  
        onClick={onClick}
        items={items}
      />
    </div>
  );
}

export default NavbarMenuDropdown;
