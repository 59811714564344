import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../button/Link";
import Menu from "./DataMenu";
import {
  Tabs
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../redux/slices/dataSlice";
import { dataSelector } from "../../redux/selectors";

function NavbarMenuDropdown({ outerClassName }) {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const location = useLocation();



  useEffect(() => {
    dispatch(
      setData({
        key: location.pathname
      })
    );
  }, [])

  // console.log("$:/location ", );

  const onChange = (key) => {
    // console.log(key);

    dispatch(
      setData({
        key: key
      })
    );

    navigate(key)
  };

  const items = [
    {
      index: 1,
      label: t("link_home_data"),
      key: "/data",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 2,
      label: t("menu_marketdata_index"),
      key: "/data/index",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 3,
      label: t("menu_marketdata_trade"),
      key: "/data/trade",
      disabled: false,
      className: "col-span-2"
    },
    {
      index: 4,
      label: t("main_signal"),
      key: "/data/signal",
      disabled: false,
      className: "col-span-2"
    },   
    {
      index: 5,
      label: t("menu_marketdata_mining"),
      key: "/data/mining",
      disabled: false,
      className: "col-span-2"
    }, 
    {
      index: 6,
      label: t("menu_marketdata_bigmovement"),
      key: "/data/bigmovement",
      disabled: false,
      className: "col-span-2"
    },
    {
      index: 7,
      label: t("menu_marketdata_pack"),
      key: "/data/pack",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 8,
      label: t("menu_marketdata_research"),
      key: "/data/research",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 9,
      label: t("main_link_portfolio"),
      key: "/data/portfolio",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 10,
      label: t("main_company_divident"),
      key: "/data/divident",
      disabled: false,
      className: "col-span-1"
    },
    {
      index: 11,
      label: t("main_otc"),
      key: "/data/otc",
      disabled: false,
      className: "col-span-2"
    },
    // {
    //   index: 12,
    //   label: t("menu_marketdata_shareowner"),
    //   key: "data/shareowner",
    //   disabled: false,
    //   className: "col-span-1"
    // },
    {
      index: 12,
      label: t("menu_marketdata_companies"),
      key: "/data/companies",
      disabled: false,
      className: "col-span-2"
    },
    {
      index: 13,
      label: t("main_board_movement"),
      key: "/data/nontrademovement",
      disabled: false,
      className: "col-span-2"
    },
    {
      index: 14,
      label: t("main_board_invest"),
      key: "/data/invest",
      disabled: false,
      className: "col-span-2"
    },  
    {
      index: 15,
      label: t("main_board_ownership"),
      key: "/data/others",
      disabled: true,
      className: "col-span-1"
    },
    {
      index: 16,
      label: t("menu_marketdata_report"),
      key: "/data/report",
      disabled: false,
      className: "col-span-1"
    },
    // {
    //   label: t("main_board_analysis"),
    //   key: "data/analysis",
    //   disabled: true,
    // },    
  ];


  return (
    <div className={"mb-4 mt-14  " + outerClassName}>
      <div>
        {/* {items.map((item, index) => (
          <Button link={"/" + item?.key} label={item?.label} index={index} disabled={item?.disabled}/>
        ))} */}
      </div>

      <Menu
        t={t}
        outerClassName="grid grid-cols-12 gap-1"
        // outerClassName="container mx-auto flex flex-wrap"
        events={{
          onChange: onChange
        }}
        items={items}
        activeKey={data.key}
      />

      {/* <Tabs 
        className="pt-2"
        defaultActiveKey="data"
        type="card"
        activeKey={data.key}
        items={items}
        onChange={onChange}
        // indicator={{ size: (origin) => origin - 20, align: "top" }}
      /> */}
    </div>
  );
}

export default NavbarMenuDropdown;
