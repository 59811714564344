import React, { useEffect } from "react";

import {
  Button,
  DatePicker,
  InputNumber,
  Form,
  Input,
  Select
} from "antd";

import dayjs from "dayjs";

const Render = ({t, data, events, form}) => {
 
  const { detail, company } = data;
  
  // console.log("$:/detail ", detail);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        id: detail?.id && detail?.id || null,
        portfolioId: detail?.portfolioId && detail?.portfolioId || null,
        companyCode: detail?.companyCode && detail?.companyCode || null,
        totalShare: detail?.totalShare && detail?.totalShare || null,
        cost: detail?.perCost && detail?.perCost || null,
        fee: detail?.fee && detail?.fee || null,
        tradeDate: detail?.tradeDate && dayjs(detail?.tradeDate) || null
      }}
      onFinish={events?.handleFinish}
    > 
      <Form.Item
        name="id"
        className="hidden"
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item 
        name="portfolioId"
        className="hidden"
      >
        <Input className="w-full"/>
      </Form.Item>
      <Form.Item 
        name="companyCode"
        label={t("main_company")}
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder={t("main_company")}
          filterOption={(input, option) => (option?.name.toLowerCase() ?? '').includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          fieldNames={{
            label: "name",
            value: "code"
          }}
          options={company}
        />
        {/* <Input /> */}
      </Form.Item>
      <Form.Item 
        name="totalShare"
        label={t("main_trade_share")}
        rules={[{ required: true }]}
      >
        <Input className="w-full" placeholder={t("main_trade_share")} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
      </Form.Item>
      <Form.Item 
        name="cost"
        label={t("main_portfolio_price")}
        rules={[{ required: true }]}
      >
        <InputNumber className="w-full" placeholder={t("main_portfolio_price")} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
      </Form.Item>
      <Form.Item 
        name="fee"
        label={t("main_portfolio_fee")}
        rules={[{ required: true }]}
        
      >
        <InputNumber className="w-full" min={1} max={10} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} placeholder={t("main_portfolio_fee")} />
      </Form.Item>
      <Form.Item 
        name="tradeDate"
        label={t("main_column_date")}
        rules={[{ required: true }]}
      >
        <DatePicker 
          className="w-full"
          // format={dateFormat} 
          placeholder={t("main_column_date")}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">{t("main_portfolio_button")}</Button>
      </Form.Item>
    </Form>
  );
};

export default Render;