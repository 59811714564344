import React, { useState } from "react";

import { Select, Space, Tag } from "antd";

const Render = ({t}) => {

  const [value, setValue] = useState("mse");

  const tagRender = ({label, value}) => {
    return (
      <Tag
        color="green"
      >
        {value}: {t("main_status_active")}
      </Tag>
    )
  }

  const options = [
    {
      label: `MSE`,
      value: "MSE"
    },
    {
      label: `UBEX`,
      value: "UBEX"
    }
  ]

  const selectProps = {
    tagRender,
    mode: "mul",
    style: { width: "100%"},
    defaultValue: ["MSE", "UBEX"],
    bordered: false,
    value,
    options, 
    onChange: newValue => {
      setValue(newValue)
    },
  }

  return (
    <div>
      {/* <Select {...selectProps} /> */}
      <Select
        bordered={false}
        mode="multiple"
        tagRender={tagRender}
        defaultValue={['MSE', 'UBEX']}
        style={{ width: '100%' }}
        options={options}
      />
    </div>
  )
}

export default Render;