import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, modalSelector } from "../../redux/selectors";

import { withTheme } from "@emotion/react";
import NavbarLogo from "./NavbarLogo";
import SubMenu from "./SubMenu";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarMenuDropdown from "./NavbarMenuDropdown";

import {
  Modal 
} from "antd";

import { useTranslation } from "react-i18next";
import { useGetApiQuery } from "../../redux/api";

import { userLogginOut } from "../../redux/slices/authSlice";
import { setModal } from "../../redux/slices/modalSlice";
import Profile from "../auth/Profile";

import GoogleAuth from "../../container/GoogleAuth";
import Portfolio from "../../container/Portfolio";
import Share from "../../container/Share";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { t } = useTranslation();

  const trade = useGetApiQuery("/mse/trades");
  const mse = useGetApiQuery("/mse/status");
  const auth = useSelector(authSelector);  
  const modal = useSelector(modalSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleModal = (value) => {
    dispatch(
      setModal({
        modal: value?.modal,
        mode: value?.mode
      })
    );
  };

  const handleOnClick = (item) => {

    if(item.key == "/logout") {
      localStorage.setItem("marketinfo", "");
      localStorage.setItem("marketprofile", "");
      dispatch(userLogginOut());
      navigate("/");
    } else {
      navigate(item.key);    
    }

    dispatch(
      setModal({
        modal: false,
      })
    );
  };

  const renderModal = (modal) => {

    switch(modal?.mode) {
      case "profile": 
        return  <Profile 
                  data={auth?.user} 
                  events={{handleOnClick: handleOnClick}} 
                />
      case "notification": 
        return <div>Notification</div>
      case "share": 
        return <Share />
      case "portfolio": 
        return <Portfolio />
      default:
        return <GoogleAuth />
    }
  };
  
  return (
    <div className="bg-white">
      <div className="w-full">
        <div className="container mx-auto">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-2 bg-white">
              <NavbarLogo />
            </div>
            <div className="col-span-8 justify-center text-center">
              <NavbarMenuDropdown />
            </div>
            <div className="col-span-2 text-right flex justify-between items-center bg-white">
              <NavbarUserDropdown t={t} data={{modal: modal?.modal, auth: auth?.isAuthenticated, user: auth?.user}} events={{handleOnClick: handleModal}} />
              {/* <IndexStatus t={t} /> */}
              {/* <NavbarLanguagesDropdown data={{auth: auth?.isAuthenticated}} /> */}
              {/* <NavbarNotification data={{auth: auth?.isAuthenticated}} /> */}
              {/* {auth.isAuthenticated && <NavbarUserDropdown />} */}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-700">
        <SubMenu t={t} data={{trade: trade?.data, status: mse?.data}} />
      </div>
      <div>
        <Modal 
          open={modal?.modal}
          onOk={() => handleModal(!modal?.modal)}
          onCancel={() => handleModal(!modal?.modal)}
          okButtonProps={{style: {display: "none"}}}
          cancelButtonProps={{style: {display: "none"}}}
        >
          {renderModal(modal)}
        </Modal>
      </div>
    </div>
  );
};

export default withTheme(Navbar);


