// Main components
import React from "react";
import Trade from "../marquee/trade";

// Sub components
import NavbarSubmenu from "./NavbarSubmenu";
import IndexStatus from "./IndexStatus";

const SubMenu = ({t, data}) => {

  // console.log("$:/submenu/data ", data);

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12">
        <div className="col-span-9">
          <Trade t={t} data={data?.trade} />
        </div>
        <div className="col-span-3">
          {/* <NavbarSubmenu t={t} /> */}
          <IndexStatus t={t} data={data?.status} />
        </div>
      </div>
    </div>
  )
}

export default SubMenu;
