import React from "react";
import styled from "@emotion/styled";

import { Badge, Grid, Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 14px;
`;

const FooterDescription = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 12px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const auth = useSelector(authSelector);
  const { user } = auth;
  return (
    <Footer {...rest}>
      {user?.name && (
        <Grid container spacing={2}>
          <Grid item>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={user?.name?.toUpperCase()}
                src="/static/img/avatars/avatar-1.jpg"
              />
            </FooterBadge>
          </Grid>
          <Grid item>
            <FooterText variant="body1">{user?.name}</FooterText>
            <FooterDescription variant="body2">{user?.role}</FooterDescription>
          </Grid>
        </Grid>
      )}
    </Footer>
  );
};

export default SidebarFooter;
