import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";
import { UserOutlined, BellOutlined } from "@ant-design/icons";

import { Tooltip, IconButton as MuiIconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { userLogginOut } from "../../redux/slices/authSlice";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarNotification from "./NavbarNotification";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown({t, data, events}) {

  const { auth, modal } = data;
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    // localStorage.setItem("yuboku", "");
    // localStorage.setItem("yuboku-annotator", "");
    // dispatch(userLogginOut({}));
    // navigate("/auth/sign-in");
  };

  // console.log("$:/auth ", data.user.imageUrl);

  return (
    <>
      {auth 
        ? (
          <div className="grid grid-cols-12 justify-center items-center">
            {/* <NavbarLanguagesDropdown outerClassName="col-span-6 w-12"/> */}
            {/* <div className="col-span-4">
              <IconButton
                aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={() => events?.handleOnClick({modal: !data?.modal, mode: "profile"})}
                color="inherit"
                size="large"
                >
                <BellOutlined />
              </IconButton>
            </div> */}
            {/* <NavbarNotification outerClassName="col-span-4" /> */}
            <div className="col-span-12 w-12">
              <IconButton
                aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={() => events?.handleOnClick({modal: !data?.modal, mode: "profile"})}
                color="inherit"
                size="large"
                >
                <Image src={data.user.imageUrl} preview={false} className="rounded-full" />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-4 items-center text-blue-700">
            <div className="col-span-2 hover:cursor-pointer" onClick={() => events?.handleOnClick({modal: !data?.modal, mode: "login"})}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div className="hidden md:block lg:block col-span-4 hover:cursor-pointer" onClick={() => events?.handleOnClick({modal: !data?.modal, mode: "login"})}>{t("auth_form_login")}</div>
            {/* <div className="hidden md:block lg:block col-span-2 border-l border-dashed border-blue-600 h-8 w-0 mx-auto"></div>
            <div className="hidden md:block lg:block col-span-4 hover:cursor-pointer" onClick={() => events?.handleOnClick({modal: !data?.modal, mode: "register"})}>{t("auth_form_register")}</div> */}
          </div>
        ) 
      }
    </>
  );
}

export default NavbarUserDropdown;
